import React, { Component, Suspense } from 'react'
import { HashRouter, Route, Routes } from 'react-router-dom'
import './scss/style.scss'
//import {withNavigate} from "./Services/WithNavigate";
//import Koneksiku from "./Services/Koneksiku";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";
import {theRoot} from "./Store/reduser";
import {withNavigate} from "./Services/WithNavigate";
import routes2 from './routes2'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
//const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

const Master1 = React.lazy(() => import("./containers/master1"));

// Pages
//const Login = React.lazy(() => import('./views/pages/login/Login'))

//const Markers= React.lazy(() => import("./maps/markers"));
const Login = React.lazy(() => import("./components2/Login2"));
//const Register = React.lazy(() => import('./views/pages/register/Register'))
//const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
//const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))
//<Route path="/login2/:paramku" element={

let sudahProses=false
class App extends Component {
  constructor(props) {
    super(props);

    this.state = {      
      bolehMasuk: false,      
    };

    //this.email = createRef()
    //this.password = createRef()
  }


  componentDidMount() {
    //if(!sudahProses){
      sudahProses=true
      console.log("totok-1 "+new Date())
      let ka=sessionStorage.getItem('masukkelas');
      console.log("totok-2 "+new Date(),ka)
      //sessionStorage.removeItem("masukkelas");
      if(ka===null || ka === undefined){
        console.log("totok-3 "+new Date())

        this.props.navigate('/login2/0', { replace: true })
        //window.location.reload(false);
        return true
        //console.log("totok-4 "+new Date())
        //navigate('/login2/0');
        //return (<Navigate to="/login2" replace={true} />)
        //return false
      }else{
        this.setState({bolehMasuk: true})
      }

      
      //console.log("totok-5 "+new Date())
      const k=sessionStorage.getItem("bigStore2.user");
      const k2=sessionStorage.getItem("gambarku");
      //console.log('lena3 '+new Date(),k)
      //console.log('lena4 '+new Date(),k2)
      let nama=''
      let gambar=''
      if(k!==null){
          let te2 = JSON.parse(k);
          //console.log('lena5 '+new Date(),te2.name)
          nama=te2.fullname
      }
      if(k2!==null){
          gambar=k2
      }

      this.props.theRoot({nama: 'infoku',nilai: {
        nama: nama,
        alamat: '',
        nilai: 0,
        gambarku: gambar,
        sidebarShow: true,
        sidebarUnfoldable: false
      }});

      
    //}
  }

  render() {
    return (<>  
    <Routes>
      {/* <Route path="/tes" element={<Tes1 />} >
      </Route> */}
      {/* <Route path="*" element={<p>There's nothing here: 404!</p>} /> */}

      <Route path="/login2/:paramku" element={
        <React.Suspense fallback={loading}><Login /></React.Suspense>      
        } />

        
      <Route path="/" element={<Master1 />} >
        {routes2.map((route, idx) => {
            return route.component && (
              <Route
                key={idx}
                path={route.path}
                exact={route.exact}
                name={route.name}
                element={
                  <React.Suspense fallback={loading}>
                    <route.component/>
                  </React.Suspense>
                }
                //render={props => (<route.component {...props} />)} 
                // element={<route.component/>}
              />
            )
          })}         
          {/* </React.Suspense>  */}
      </Route>
      <Route path="*" 
      element={
        <React.Suspense fallback={<>...</>}>
          <NoMatch />
        </React.Suspense>
      }
      />

    </Routes>

      {/* {!this.state.bolehMasuk ?  <Login/>  : 
        <Suspense fallback={loading}>
          <Routes>                                
            <Route exact path="/map2" name="Maps Page" element={<Markers />} />
            <Route exact path="/login2/:paramku" name="Login Page" element={<Login />} />
            <Route exact path="/register" name="Register Page" element={<Register />} />
            <Route exact path="/404" name="Page 404" element={<Page404 />} />
            <Route exact path="/500" name="Page 500" element={<Page500 />} />
            <Route path="*" name="Home" element={<DefaultLayout />} />
          </Routes>
        </Suspense>
      } */}
      
      </>)
  }
}

/*
const mapStateToProps = (state) => ({
  generalState: state.generalState,
});
*/
export default connect((state) => ({ generalState: state.generalState }), { theRoot 
})(withNavigate(App));


const NoMatch = () => {
  return <p>There's nothing here: 404!</p>;
};


//export default App
//export default (withNavigate(App));

